<template>
	<el-container style="height: 100vh;">
		<el-header class="header">
			<Header :systemlist="systemlist"></Header>
		</el-header>
		<el-container style="overflow: hidden;">
			<el-aside class="navbar" :style="{ width: isCollapse ? '96px' : '220px' }">
				<NavBarLeft :list="navList" :is-collapse="isCollapse"></NavBarLeft>
			</el-aside>
			<el-main class="main">
				<div class="breadcrumb flex">
					<div class="flex">
						<div class="arrow" @click="isCollapse = !isCollapse">
							<local-svg iconClass="arrow-right"
								:style="{ 'transform': isCollapse ? 'rotateZ(180deg)' : 'rotateZ(0deg)' }"></local-svg>
						</div>
						<local-svg iconClass="location" class="location"></local-svg>
						<el-breadcrumb separator="/">
							<el-breadcrumb-item>{{ rootdirectory }}</el-breadcrumb-item>
							<el-breadcrumb-item v-for="(item, index) in nowpage" :key="index">{{ item
							}}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="refresh" @click="reload"><local-svg iconClass="refresh"></local-svg></div>
				</div>
				<div class="main-body"><router-view :key="$route.fullPath" /></div>
				<span class="technology">两航（天津）数字科技有限公司</span>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import NavBarLeft from '@/components/Navbar.vue'
import Header from '@/components/Header.vue'

let context = require.context('@/menu', false, /\.js$/)
const menu = {}
for (let key of context.keys()) {
	const keyArr = key.split('/')[1];
	menu[keyArr.replace('.js', '')] = context(key).default
}

export default {
	name: 'home',
	data() {
		return {
			navList: [],
			isCollapse: false,
			nowpage: [],
			systemlist: [
				{ name: "数据驾驶舱", index: 0, value: 'visual' },
				{ name: "经开三十年", index: 1, value: 'thirty' },
				{ name: "多规合一", index: 2, value: 'gauge' },
				{ name: "六链合一", index: 3, value: 'chain' },
				// { name: "零碳岛", index: 4, value: 'carbon' },
				{ name: "智领经开", index: 5, value: 'jingkai' },
				{ name: "智领安全", index: 6, value: 'security' },
				{ name: "系统管理", index: 7, value: 'system' },
			],
			rootdirectory: '',
		}
	},
	components: {
		NavBarLeft,
		Header
	},
	mounted() {
		let data = this.$route.path.split("/")[1];
		this.navList = this.getMenuList(menu[data]);
		this.getDirectory(this.$route.path);
	},
	methods: {
		reload() {
			window.location.reload()
		},
		getMenuList(data) {
			if (!data) {
				return
			}
			let menu = [];
			let power = this.$store.state.userpower;
			for (let i in power) {
				let list = data.find(x => x.route == power[i].route)
				if (list) {
					if (power[i].has_child == 1) {
						let arr = [];
						for (let m in power[i].child) {
							let p = list.page.find(x => x.route == power[i].child[m].route);
							if (p) {
								arr.push(p)
							}
						}
						list.page = arr;
						menu.push(list)
					} else {
						menu.push(list)
					}
				}
			}
			return menu;
			// return data;
		},
		getDirectory(path) {
			let root = path.split('/')[1];
			if (!root) return;
			let d = this.systemlist.find(x => x.value == root)
			this.rootdirectory = d.name;

			for (let i in this.navList) {
				let obj = this.navList[i]
				if (obj.has_child && obj.page.length > 0) {
					let name1 = obj.name;
					for (let i in obj.page) {
						if (obj.page[i].route == path) {
							this.nowpage = [name1, obj.page[i].name];
							return
						}
					}

				} else {
					if (obj.route == path) {
						this.nowpage = [obj.name];
						return
					}
				}
			}
		}
	},
	watch: {
		$route: {
			handler: function(val, oldVal) {
				let path = val.path;
				this.navList = this.getMenuList(menu[path.split("/")[1]]);
				this.getDirectory(path);
			},
			immediate: true,
		},
	},
}

</script>

<style lang="scss" scoped>
.header {
	height: 64px !important;
	padding: 0 !important;
	background-color: #fff;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.refresh {
	color: #30498B;
	cursor: pointer;

	&:hover {
		color: #2C80FF;
	}
}

.el-main {
	padding: 0 !important;
	display: flex !important;
	flex-direction: column;

	.breadcrumb {
		height: 32px;
		padding-left: 24px;
		padding-right: 56px;
		justify-content: space-between;

		.arrow {
			font-size: 16px;
			margin-left: 10px;
			cursor: pointer;
		}

		.location {
			font-size: 13px;
			margin: 0 5px 0 12px;
		}

		.el-breadcrumb {
			font-size: 12px;
		}

		::v-deep .el-breadcrumb__inner {
			color: #a3aecb;
		}

		.el-breadcrumb__item:last-child {
			::v-deep .el-breadcrumb__inner {
				color: #606b83;
			}
		}
	}

	.main-body {
		flex: 1;
		overflow: auto;
	}
}



.navbar {
	// width: 220px !important;

	::v-deep .el-menu--collapse {
		width: auto !important;
	}


	.el-aside {
		transition: width 0.15s;
		-webkit-transition: width 0.15s;
		-moz-transition: width 0.15s;
		-webkit-transition: width 0.15s;
		-o-transition: width 0.15s;
	}

	.is-opened {
		display: block;
		background: white !important;
	}
}


.el-aside::-webkit-scrollbar {
	display: none;
}

.technology {
	position: absolute;
	height: 30px;
	font-size: 12px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	color: #C7C8CA;
}

.main {
	position: relative;
	background: #F2F7FF;
}
</style>
