<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  background: #f2f5fb;
  color: #2c3e50;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c2c8d5;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eff1f5;
}

.hover {
  cursor: pointer !important;
}

.disabled {
  cursor: not-allowed;
}

* {
  margin: 0;
  padding: 0;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // user-select: none;
  // -ms-user-select: none;
}
.public-pag .el-pager li.active{
  color:#1D78FE;
}
.public-pag .el-pager li:hover{
  color:#1D78FE;
}
.public-pag {
  border-top: none !important;
}
@import "@/assets/scss/style.scss";
@import "@/assets/scss/formstyle.scss";




</style>
