import request from "../utils/request";

//登录
export function login(data) {
  return request({
    url: "/v1/hall/admin/login",
    method: "post",
    data,
  });
}

export function userList(data) {
  return request({
    url: "/v1/hall/admin/whole",
    method: "get",
    params: data,
  });
}

export function userTable(data) {
  return request({
    url: "/v1/hall/admin/user",
    method: "get",
    params: data,
  });
}

export function getdepartTree(data) {
  return request({
    url: "/v1/hall/admin/tree",
    method: "get",
    params: data,
  });
}

export function addBranch(data) {
  return request({
    url: "/v1/hall/admin/branch",
    method: "post",
    data,
  });
}

export function detailBranch(data) {
  return request({
    url: "/v1/hall/admin/branch",
    method: "patch",
    data,
  });
}

export function editBranch(data) {
  return request({
    url: "/v1/hall/admin/branch",
    method: "put",
    data,
  });
}

export function delBranch(data) {
  return request({
    url: "/v1/hall/admin/branch",
    method: "delete",
    data,
  });
}

export function getBranch(data) {
  return request({
    url: "/v1/hall/admin/branch",
    method: "get",
    params: data,
  });
}

export function linkBranch(data) {
  return request({
    url: "/v1/hall/admin/already",
    method: "get",
    params: data,
  });
}

export function unRelevance(data) {
  return request({
    url: "/v1/hall/admin/relevance",
    method: "delete",
    params: data,
  });
}

export function Relevance(data) {
  return request({
    url: "/v1/hall/admin/relevance",
    method: "post",
    data,
  });
}

export function changeState(data) {
  return request({
    url: "/v1/hall/admin/state",
    method: "post",
    data,
  });
}

export function roleTree(data) {
  return request({
    url: "/v1/hall/admin/index",
    method: "get",
    params: data,
  });
}
export function roleTable(data) {
  return request({
    url: "/v1/hall/admin/role",
    method: "get",
    params: data,
  });
}
export function unRoleLink(data) {
  return request({
    url: "/v1/hall/admin/association",
    method: "delete",
    params: data,
  });
}
export function RoleDetail(data) {
  return request({
    url: "/v1/hall/admin/role",
    method: "patch",
    params: data,
  });
}
export function addRole(data) {
  return request({
    url: "/v1/hall/admin/role",
    method: "post",
    data,
  });
}
export function editRole(data) {
  return request({
    url: "/v1/hall/admin/role",
    method: "put",
    data,
  });
}
export function delRole(data) {
  return request({
    url: "/v1/hall/admin/role",
    method: "delete",
    data,
  });
}
export function linkRole(data) {
  return request({
    url: "/v1/hall/admin/before",
    method: "get",
    params: data,
  });
}
export function association(data) {
  return request({
    url: "/v1/hall/admin/association",
    method: "post",
    data,
  });
}

export function userDetail(data) {
  return request({
    url: "/v1/hall/admin/user",
    method: "patch",
    params: data,
  });
}

export function addUser(data) {
  return request({
    url: "/v1/hall/admin/user",
    method: "post",
    data,
  });
}
export function editUser(data) {
  return request({
    url: "/v1/hall/admin/user",
    method: "put",
    data,
  });
}

export function delUser(data) {
  return request({
    url: "/v1/hall/admin/user",
    method: "delete",
    data,
  });
}

// 操作日志
export function getLog(data) {
  return request({
    url: "/v1/hall/admin/table",
    method: "get",
    params: data,
  });
}
// 权限列表
export function powerList(data) {
  return request({
    url: "/v1/hall/admin/power",
    method: "get",
    params: data,
  });
}
// 权限列表
export function powerDetail(data) {
  return request({
    url: "/v1/hall/admin/detail",
    method: "get",
    params: data,
  });
}

// 权限列表
export function postPower(data) {
  return request({
    url: "/v1/hall/admin/edit",
    method: "post",
    data,
  });
}

// 权限列表
export function paramList(data) {
  return request({
    url: "/v1/hall/admin/option",
    method: "get",
    params: data,
  });
}

export function paramTable(data) {
  return request({
    url: "/v1/hall/admin/param",
    method: "get",
    params: data,
  });
}

export function postParam(data) {
  return request({
    url: "/v1/hall/admin/param",
    method: "post",
    data,
  });
}

export function delParam(data) {
  return request({
    url: "/v1/hall/admin/param",
    method: "delete",
    params: data,
  });
}

export function newUploadFile(data) {
  return request({
    url: "/v1/hall/admin/uploadFile",
    method: "post",
    data,
  });
}

export function getPerson(data) {
  return request({
    url: "/v1/hall/admin/center",
    method: "get",
    params: data,
  });
}

export function editPerson(data) {
  return request({
    url: "/v1/hall/admin/center",
    method: "post",
    data,
  });
}

export function sendCode(data) {
  return request({
    url: "/v1/hall/admin/sms",
    method: "post",
    data,
  });
}

export function forgetPwd(data) {
  return request({
    url: "/v1/hall/admin/reset",
    method: "post",
    params: data,
  });
}

//代办
export function todoList(data) {
  return request({
    url: "/v1/zljkback/dealt/list",
    method: "get",
    params: data,
  });
}

export function adminInfo(data) {
  return request({
    url: "/v1/zljkback/dealt/adminInfo",
    method: "post",
    data,
  });
}

//消息
export function message(data) {
  return request({
    url: "/v1/hall/admin/message",
    method: "get",
    params: data,
  });
}

export function clearmessage(data) {
  return request({
    url: "/v1/hall/admin/clear",
    method: "get",
    params: data,
  });
}


// 企业用户审核
export function EnterpriseAudit(data) {
  return request({
    url: "/v1/hall/admin/audit",
    method: "post",
    params: data,
  });
}

