export default [
  {
    name: "企业管理",
    route: "/security/business-manage",
    icon: "build",
  },
  {
    name: "安全管理",
    route: "/security/safe-manage",
    icon: "shield",
  },
  {
    name: "安全检查",
    route: "/security/safe-check",
    icon: "info",
  },
  {
    name: "宣传培训",
    route: "/security/publicity-training",
    icon: "xcpx",
    has_child: 1,
    page: [
      {
        name: "工作提醒",
        route: "/security/work-reminder",
      },
      {
        name: "政策文件",
        route: "/security/policy-file",
      },
      {
        name: "警示教育",
        route: "/security/warn-education",
      },
      {
        name: "宣传教育",
        route: "/security/publicity-education",
      },
    ],
  },
  {
    name: "我的管理",
    route: "/security/admin",
    icon: "setting",
    has_child: 1,
    page: [
      {
        name: "关于我们",
        route: "/security/admin-aboutus",
      },
      {
        name: "问卷调查",
        route: "/security/issue-survey",
      },
    ],
  },
];
