import router from "../router/index";
import store from "../store/index";
import { Message } from "element-ui";

export function routesData(data) {
  // 重新加载一次 VueRouter
  router.reloadRouter();
  let array = [];
  let d = {
    visual: 0,
    thirty:0,
    gauge:0,
    chain:0,
    jingkai: 0,
    security: 0, 
    system: 0,
  };
  data.forEach((item) => {
    if (item.has_child == 1) {
      item.child.forEach((i) => {

        for(let m in d){
          if (d[m] == 0) {
            let a = i.route.split("/");
            if (a[1] ==m) {
              i.module =m;
              d[m]++;
            }
          }
        }
         
        // console.log(i);
        // 组装路由配置
        const temp = {
          name: i.module,
          path: i.route,
          component: () => import(`../views/${i.comp_addr}.vue`),
        };
        // console.log(`../views/${i.comp_addr}.vue`);
        // 动态添加为 index 的子路由
        router.addRoute("home", temp);
        array.push(temp);
      });
    } else {

      for(let m in d){
        if (d[m] == 0) {
          let a = item.route.split("/");
          if (a[1] ==m) {
            item.module =m;
            d[m]++;
          }
        }
      }
 
      const temp = {
        name: item.module,
        path: item.route,
        component: () => import(`../views/${item.comp_addr}.vue`),
      };
      // console.log(JSON.stringify(temp));
      router.addRoute("home", temp);
      // array.push(temp)
    }
  });
  if (data.length > 0) {
    let path = data[0].has_child == 1 ? data[0].child[0].route : data[0].route;
    store.state.firstPage = path;
  } else {
    Message.warning("用户无查看页面权限");
    window.localStorage.removeItem("TOKEN");
  }
}

if (store.state.userpower.length > 1) {
  console.log(1);
}

export default {
  inserted(el, bindling) {
    //bindling.value为指令的绑定值
    let perVal = bindling.value;
    if (bindling.value) {
      //假设某用户对某模块只有添加和删除的权限
      //这个权限信息(即pers)应该是不同用户登录时从后台拿到的对应的信息
      let pers = store.state.userbtn;
      //hasPer为true为有权限
      //hasPer为false为无权限
      // let hasPer = pers.some((item) => {
      //   return item == perVal;
      // });
      //没有权限就先隐藏此元素吧
      if (!pers.includes(bindling.value)) {
        (el.parentNode && el.parentNode.removeChild(el)) ||
          (el.style.display = "none");
      }
    }
  },
};
