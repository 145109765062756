export default [
  {
    name: "产业链管理",
    route: "/chain/industry-chain",
    icon: "carousel",
  },
  {
    name: "服务链管理",
    route: "/chain/service-chain",
    icon: "info",
  },
  {
    name: "创新链管理",
    route: "/chain/innovation-chain",
    icon: "link",
  },
  {
    name: "人才链管理",
    route: "/chain/talent-chain",
    icon: "zhaoshang",
  },
  {
    name: "金融链管理",
    route: "/chain/finance-chain",
    icon: "party",
  },
  {
    name: "生态链管理",
    route: "/chain/ecology-chain",
    icon: "leaf",
  },
];
