import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import unfind from "../views/404.vue";
import develop from "../views/develop.vue";
import { routesData } from "@/utils/permission";
import store from "../store/index";
Vue.use(VueRouter);

// import index from "../views/jingkai/user/index.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    // redirect: "/jingkai/carousel",
    children: [],
  },
  {
    path: "/404",
    name: "404",
    component: unfind,
  },
  {
    path: "/develop",
    name: "develop",
    component: develop,
  },
  {
    path: "/login",
    name: "login",
    component: () => import(`../views/login/login.vue`),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import(`../views/login/forgotLogin.vue`),
  },
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
VueRouter.prototype.replace = function replace(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const router = new VueRouter({
  routes,
});

router.reloadRouter = function () {
  router.matcher = new VueRouter({
    routes,
  }).matcher;
};

let hasRoles = true;
router.beforeEach(async (to, from, next) => {
  // console.log(to);
  if (to.matched.length === 0 && hasRoles == false) {
    next("/404");
  } else {
    // 要去登录界面,直接放行
    if (to.path === "/login" || to.path === "/forget") {
      next();
    } else {
      let token = window.localStorage.getItem("TOKEN");
      if (!token) {
        next("/login");
      } else {
        if (hasRoles) {
          await routesData(store.state.userpower);
          hasRoles = false;
          next({ ...to, replace: true });
        } else {
          next();
        }
        if (to.path === "/" && !!store.state.firstPage) {
          next(store.state.firstPage);
        }
        // next();
      }
    }
  }
});

export default router;
