export default [
  {
    name: "首页展示",
    icon: "home",
    has_child: 1,
    route: "/jingkai/home",
    page: [
      {
        name: "轮播图",
        route: "/jingkai/carousel",
      },
      {
        name: "招商",
        route: "/jingkai/zhaoshang",
      },
      {
        name: "党建",
        route: "/jingkai/party",
      },
      {
        name: "企服",
        route: "/jingkai/qifu",
      },
      {
        name: "政策",
        route: "/jingkai/policy",
      },
      {
        name: "经开资讯",
        route: "/jingkai/info",
      },
    ],
  },
  {
    name: "产业圈",
    icon: "industrial",
    has_child: 1,
    route: "/jingkai/industrial",
    spacing: 1.2,
    page: [
      {
        name: "内容管理",
        route: "/jingkai/industrial-list",
      },
      {
        name: "评论管理",
        route: "/jingkai/industrial-comment",
      },
      {
        name: "产业设置",
        route: "/jingkai/industrial-set",
      },
    ],
  },
  {
    name: "特色场景",
    icon: "feature",
    has_child: 1,
    route: "/jingkai/feature",
    spacing: 1,
    page: [
      {
        name: "活动预约",
        route: "/jingkai/activity-reservation",
      },
      {
        name: "问卷调查",
        route: "/jingkai/question-investigate",
      },
      {
        name: "共享空间",
        route: "/jingkai/share-space",
      },
    ],
  },
  {
    name: "我的管理",
    route: "/jingkai/system",
    icon: "setting",
    has_child: 1,
    page: [
      {
        name: "意见反馈",
        route: "/jingkai/mine-feedback",
      },
      {
        name: "关于我们",
        route: "/jingkai/mine-aboutus",
      },
    ],
  },
];
