import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import Fragment from "vue-fragment";
import axios from "axios";
import { message } from "./utils/message";

import "element-ui/lib/theme-chalk/index.css";
import "@/icons";
import Directives from "./utils/index";
import common from "./utils/common";

// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'

// import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module

Vue.use(Directives);
Vue.use(common);
// Vue.use(VueVideoPlayer);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.use(Fragment.Plugin);
Vue.prototype.$bus = new Vue();
Vue.prototype.$message = message;
// Vue.prototype.$BASE_URL = "http://192.168.9.47/jkqapi/public/";
// Vue.prototype.$BASE_URL = "http://192.168.9.107:8091/";
Vue.prototype.$BASE_URL = "https://dljkq.lianghang.cc/";
// Vue.prototype.$BASE_URL = "https://deda.lianghang.cc/";

ElementUI.Dialog.props.closeOnClickModal.default = false;

// Vue.component("vue-ueditor-wrap", VueUeditorWrap);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
