export default [
  {
    name: "单元规划用地",
    route: "/gauge/unit-plan-land",
    icon: "carousel",
  },
  {
    name: "多规合一",
    route: "/gauge/multiple-merge",
    icon: "info",
  },
];
