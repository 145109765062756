export default [
  {
    name: "政府用户",
    route: "/system/user",
    icon: "user",
  },
  {
    name: "企业用户",
    route: "/system/enterprise-user",
    icon: "build",
  },
  {
    name: "部门管理",
    route: "/system/department",
    icon: "part",
  },
  {
    name: "角色管理",
    route: "/system/role",
    icon: "role",
  },
  // {
  //   name: "菜单管理",
  //   route: "/system/menu",
  //   icon: "qrcode",
  // },
  {
    name: "权限管理",
    route: "/system/power",
    icon: "lock-key",
  },
  {
    name: "待办事项",
    route: "/system/todolist",
    icon: "clock",
  },
  {
    name: "消息中心",
    route: "/system/message",
    icon: "envelope",
  },
  {
    name: "参数管理",
    route: "/system/params",
    icon: "slider",
  },
  {
    name: "操作日志",
    route: "/system/operatelog",
    icon: "note",
  },
  {
    name: "个人中心",
    route: "/system/personal",
    icon: "person",
  },
];
