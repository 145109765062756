let tTimer;
let flag;
let timeout = null;

const global = {
  install: function (Vue) {
    Vue.prototype.$common = this;
  },
  // 深度克隆对象
  deepClone(target) {
    let result;
    if (typeof target === "object") {
      if (Array.isArray(target)) {
        result = [];
        for (const i in target) {
          result.push(this.deepClone(target[i]));
        }
      } else if (target === null) {
        result = null;
      } else if (target.constructor === RegExp) {
        result = target;
      } else {
        result = {};
        for (const i in target) {
          result[i] = this.deepClone(target[i]);
        }
      }
    } else {
      result = target;
    }
    return result;
  },
  // 节流原理：在一定时间内，只能触发一次
  throttle(func, wait = 500, immediate = true) {
    if (immediate) {
      if (!flag) {
        flag = true;
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === "function" && func();
        tTimer = setTimeout(() => {
          flag = false;
        }, wait);
      }
    } else {
      if (!flag) {
        flag = true;
        // 如果是非立即执行，则在wait毫秒内的结束处执行
        tTimer = setTimeout(() => {
          flag = false;
          typeof func === "function" && func();
        }, wait);
      }
    }
  },
  // 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
  debounce(func, wait = 500, immediate = false) {
    if (timeout !== null) clearTimeout(timeout);
    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) typeof func === "function" && func();
    } else {
      timeout = setTimeout(function () {
        typeof func === "function" && func();
      }, wait);
    }
  },
  formatDate(time) {
    let date = new Date(time * 1000);
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return `${Y}-${M}-${D} ${h}:${m}:${s}`;
  },
};

export default global;
