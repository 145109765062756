<template>
    <div class="home">
        <div class="main">
            <div style="position: relative;">
                <img class="image" src="@/assets/image/develop.png" alt="">
                <div class="tip">抱歉，页面升级中，点击 <span @click="backPage">返回上一页</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return {
            image: ''
        }
    },
    methods: {
        backPage() {
            this.$router.go(-2)
        },
    }
}

</script>

<style lang="scss" scoped>
.home {
    background: #F2F6FD;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
        display: flex;
        margin-bottom: 30px;

        image {
            width: 428px;
        }

        .tip {
            color: #9A9EAA;
            font-size: 16px;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 20px;

            span {
                color: #1D78FE;
                text-decoration: underline;
                text-underline-offset: 3px;
                cursor: pointer;
            }
        }

    }
}
</style>
  